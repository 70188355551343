import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chat from "./components/Chat";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/chat/:uid" element={<Chat />} />
          {/* <Route path="/scan" element={<QRScanner />} />
          <Route path="/solarBot" element={<SolarBot />} />
          <Route path="/chat/:uid" element={<ChatbotNew />} /> */}
        </Routes>
      </div>
      <ToastContainer   position="top-right" // Change to "bottom-right" for bottom positioning
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/>
    </Router>

  );
}

export default App;
