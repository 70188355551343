import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function FormModal({
  show,
  handleClose,
  handleSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  setAddress,
  error,
}) {

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Talk to agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFirstName">
            <Form.Label className="fw-bold">
              First Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              pattern="[^ ]*"
              title="Space not allowed!"
              onChange={(e) => setFirstName(e.target.value)}
              maxLength={15}
              minLength={3}
              required
            />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label className="fw-bold mt-3">
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              value={lastName}
              pattern="[^ ]*"
              onChange={(e) => setLastName(e.target.value)}
              title="Space not allowed!"
              maxLength={15}
              minLength={3}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label className="fw-bold mt-3">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              pattern="[^ ]*"
              onChange={(e) => setEmail(e.target.value)}
              maxLength={30}
              required
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label className="fw-bold mt-3">Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              pattern="^[0-9]*$"
              title="Required only number"
              minLength={6}
              maxLength={10}
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label className="fw-bold mt-3">Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              maxLength={50}
              pattern="^[^\s].*"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
          {error && (<p style={{ color: 'red', marginTop:"10px" }}>{error}</p>)}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
