import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import axios from "axios";
import "./bot.css";
import chatbotImage from "../assets/images/Chatbot.png";
import agentIcon from "../assets/images/Agent.png";
import userIcon from "../assets/images/User.jfif";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
import FormModal from "./FormModal";
import deleteIcon from "../assets/images/Delete-24.png";
import { v4 as uuidv4 } from "uuid";
import { Button, Spinner } from "react-bootstrap";

const Chat = () => {
  const scrollRef = useRef(null);
  const [responses, setResponses] = useState([]); // State to track multiple responses
  const [responsesNew, setResponsesNew] = useState([]);
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const [initialMessage, setInitialMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState([""]);
  const [messageInput, setMessageInput] = useState([]);
  const [senderID, setSenderID] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [agentName, setAgentName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [displayInputValue, setDisplayInputValue] = useState("");
  const [notiFicationObject, setNotiFicationObject] = useState({});
  const [confirmAddress, setConfirmAddress] = useState("");
  const [confirmFullName, setConfirmFullName] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [homeAddressCorrect, setHomeAddressCorrect] = useState("yes");
  const [notificationConfirmation, setNotificationConfirmation] = useState(false);
  const [error, setError] = useState('');
  const [customerTime, setCustomerTime] = useState(null);
  
  const [btnDisable, setBtnDisable] = useState({
    yes: false,
    no: false,
  });

  const { uid } = useParams();
  const uuid = uid;
  let myuuid = uuidv4();
  const socketUrl = `wss://uat-api.veragoodrep.com/ws/live-chat/${uuid}/`;

  const payload = {
    id: myuuid,
    senderId: senderID,
    customer_id: senderID,
    message: displayInputValue,
    timestamp: new Date().toISOString(),
  };

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    payload
  );

  const [isLoading, setIsLoading] = useState(true);
  const [checkUUID, setCheckUUID] = useState("");

  /* write code for auto scroll start*/
function autoScroll() {
  const container = document.getElementById('scrollable-container');
  if (container) {
      container.scrollTop = container.scrollHeight;
  } 
}
  /* write code for auto scroll end*/

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://uat-api.veragoodrep.com/check-sales-representative/${uuid}`);
        setIsLoading(true); // Set loading to false after the response is received

        if (res?.data?.exists === true) {
          setIsLoading(false); 
          setCheckUUID(true);
        } else {
          setIsLoading(false); 
          setCheckUUID(false);
        }
      } catch (error) {
        setIsLoading(false); // Ensure loading is false on error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [uuid]); // Use uuid as a dependency

  useEffect(() => {
    if (lastMessage !== null) {
      let chatData = JSON.parse(lastMessage?.data);
      setMessageHistory((prev) => prev.concat(lastMessage));
      if (chatData?.senderId === senderID && notificationConfirmation) {
        setCustomerTime(chatData?.timestamp)
        // If the senderId matches, store the message under user1
        setChatMessage((prevChatMessages) => [
          ...prevChatMessages,
          { user1: chatData?.message, user2: null }, // Initialize user2 as null
        ]);
      } else {
        // If the senderId does not match, store the message under user2
        if(chatData?.senderId === uuid && notificationConfirmation){
          setChatMessage((prevChatMessages) => [
            ...prevChatMessages,
            { user1: null, user2: chatData?.message }, // Initialize user1 as null
          ]);
        }  
      }
    }
  }, [lastMessage, sendMessage]);

  const handleClickSendMessage = (e) => {
    // sendMessage(JSON.stringify(payload))
    e.preventDefault();
    sendMessage(JSON.stringify(payload));
    //setChatMessage( [...chatMessage, displayInputValue]);
    setDisplayInputValue([]);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  console.log(connectionStatus);
  /* End Live Chat Integration Code */

  const handleClick = async (message) => {
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses([data.response]);
      setIsVisible(true);
      setInputVisible(false);
      setShowInput(false);
      setAgentName(null);
      setMessages([]);
      setChatMessage([]);
      setNotificationConfirmation(false);
    } catch (error) {
      toast.error("Something Went Wrong");
      console.error("Error fetching data:", error);
    }
  };

  const handleYesClick = async () => {

    console.log(messages);

    const formData = new FormData();
    console.log(initialMessage);
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
      setBtnDisable((prevState) => ({
        yes: true,
        no: false,
      }));
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      // Format the date and time
      const formattedDateTime = now.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      setCurrentDateTime(formattedDateTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setConfirmAddress(inputValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const handleYesHometownClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const handleNoHometownClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "no");
    console.log(
      initialMessage,
      inputValue,
      "241 Line code handleNoHometownClick method"
    );

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setHomeAddressCorrect("no");
      const data = res.data;
      setResponsesNew((prevResponses1) => [...prevResponses1, data.response]);
      //setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const handleSpouseYesClick = async() => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  }

  const handleSpouseNoClick = async() => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "no");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setHomeAddressCorrect("no");
      let msg = "Thanks for visit / interest and offers to email them the conversation they had today"
      setResponsesNew((prevResponses1) => [...prevResponses1, msg]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  }

  const [inputNameValue, setInputNameValue] = useState("");

  const handleNameInputChange = (event) => {
    setInputNameValue(event.target.value);
  };

  const handleFormSubmit2 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setConfirmFullName(inputNameValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Please enter first name and last name");
    }
  };

  const [inputEmailValue, setInputEmailValue] = useState("");

  const handleEmailInputChange = (event) => {
    setInputEmailValue(event.target.value);
  };

  const handleFormSubmit3 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setConfirmEmail(inputEmailValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const [inputPhoneValue, setInputPhoneValue] = useState("");

  const handlePhoneInputChange = (event) => {
    setInputPhoneValue(event.target.value);
  };

  const handleFormSubmit4 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setConfirmPhone(inputPhoneValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const handleYesBillClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [inputBillValue, setInputBillValue] = useState("");

  const handleBillInputChange = (event) => {
    setInputBillValue(event.target.value);
  };

  const handleFormSubmit5 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLastYesClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "yes");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      createnewLeadDyanamic();
      //setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNoClickForBill = async () => {
    console.log("button yes is clicked");
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [questionNumber, setQuestionNumber] = useState("");
  const [flag, setFlag] = useState(0);

  const parposalQuestion = async (questionNumber) => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");
    formData.append("message11", questionNumber);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;

      if (flag < 1) {
        setResponses((prevResponses) => [...prevResponses, data.response]);
        setFlag(1);
      } else {
        responses.pop();
        setResponses((prevResponses) => [...prevResponses, data.response]);
      }

      setQuestionNumber(questionNumber);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [customerRequest, setCustomerRequest] = useState("");

  const lastQuestionConfirmation = async (customerRequest) => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");
    formData.append("message11", questionNumber);
    formData.append("message12", customerRequest);

    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setCustomerRequest(customerRequest);
      toast.success(data.response);
      setResponses((prevResponses) => [...prevResponses, data.response]);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick1 = async (message) => {
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    console.log(formData, "formData");
    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = res.data;
      setResponses([data.response]);
      setIsVisible(true);
      setShowInput(false);
      setAgentName(null);
      setInputVisible(false);
      setNotificationConfirmation(false);
      setChatMessage([]);
      setMessages([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleClick2 = async (message) => {
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    try {
      const res = await axios.post(
        `https://uat-api.veragoodrep.com/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = res.data;
      setResponses([data.response]);
      setIsVisible(true);
      setShowInput(false);
      setAgentName(null);
      setInputVisible(false);
      setNotificationConfirmation(false);
      setChatMessage([]);
      setMessages([]);
    } catch (error) {
      toast.error("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const [isInputVisible, setInputVisible] = useState(false);

  const toggleInputVisibility = () => {
    setResponses([]);
    setResponsesNew([]);
    setInputVisible(!isInputVisible);
    setShowInput(false);
    setNotificationConfirmation(false);
    setAgentName(null);
    setChatMessage([]);
  };

  const handleKeyDown = async (e) => {
    const formData = new FormData();
    formData.append("message", inputValue);
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      if (inputValue.trim()) {
        try {
          const response = await axios.post(
            "https://uat-api.veragoodrep.com/chat/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setMessages([
            ...messages,
            { user: inputValue, bot: response.data.response },
          ]);
          setInputValue(""); // Clear input after sending
          setChatMessage([]);
        } catch (error) {
          console.error("Error calling the API:", error);
        }
      }
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setInputVisible(false);
    setShowModal(false);
    /* Clear the input fields */
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setShowInput(false);
    setNotificationConfirmation(false);
    setAgentName(null);
  };

  const handleLeadNotification = async (leadData, chat_coversation_check) => {
    const requestBody = {
      sales_representative_id: leadData.SaleRepresentative_id,
      customer_id: leadData.customer_id,
      home_owner_first_name: leadData.home_owner_first_name,
      home_owner_last_name: leadData.home_owner_last_name,
      chat_coversation: chat_coversation_check,
    };

    try {
      const response = await axios.post(
        "https://uat-api.veragoodrep.com/send-notification/",
        requestBody
      );
      setNotificationConfirmation(true);
    } catch (error) {
      console.error("Error notification sending failed:", error);
      toast.error("Notification sending failed. Please try again!"); // Show error toast
    }
  };

  const closeSocket = async (event) => {
    event.preventDefault();
    let result = await handleLeadNotification(notiFicationObject, "end");
    window.location.reload();
  };

  const createnewLeadDyanamic = async (event) => {
    let nameSplit = confirmFullName.split(" ");

    if (nameSplit.length < 1) {
      nameSplit[0] = "Test";
      nameSplit[1] = "User";
    }

    const requestBody = {
      home_owner_first_name: nameSplit[0],
      home_owner_last_name: nameSplit[1],
      phone_no: confirmPhone,
      email_ID: confirmEmail,
      home_address: confirmAddress,
      SaleRepresentative_id: uuid,
    };

    try {
      const response = await axios.post(
        "https://uat-api.veragoodrep.com/leads-create-with-form/create/",
        requestBody
      );
      setSenderID(response.data.customer_id); // Log the success message
      setNotiFicationObject(response.data);
      toast.success(response.data.message);
      setShowInput(true);
      await handleLeadNotification(response.data, "start");
      setAgentName(response.data.SalesRepresentative_full_name);
      setResponses([]);
      setResponsesNew([]);
    } catch (error) {
      toast.error("Lead creation failed. Please try again!"); // Show error toast
      // Handle error appropriately
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (!firstName.trim()) {
    //     setError('First Name cannot be empty or just whitespace.');
    //     return;
    // }
    // if (!lastName.trim()) {
    //     setError('Last Name cannot be empty or just whitespace.');
    //     return;
    // }
    // if (!email.trim()) {
    //     setError('Email cannot be empty or just whitespace.');
    //     return;
    // }
    // Construct the request body
    const requestBody = {
      home_owner_first_name: firstName,
      home_owner_last_name: lastName,
      phone_no: phone,
      email_ID: email,
      home_address: address,
      SaleRepresentative_id: uuid,
    };
  
    try {
      const response = await axios.post(
        "https://uat-api.veragoodrep.com/leads-create-with-form/create/",
        requestBody
      );
      setSenderID(response.data.customer_id); // Log the success message
      setNotiFicationObject(response.data);
      handleClose();
      toast.success(response.data.message);
      setShowInput(true);
      await handleLeadNotification(response.data, "start");
      setAgentName(response.data.SalesRepresentative_full_name);
      setResponses([]);
      setMessages([]);
      setResponsesNew([]);
    } catch (error) {
      toast.error("Lead creation failed. Please try again!"); // Show error toast
      // Handle error appropriately
      handleClose();
    }
  };

  const handleInputChangeValue = (event) => {
    setDisplayInputValue(event.target.value);
    setMessageInput(event.target.value);
  };

  return (
    <div ref={scrollRef}>
      <div className="vera-outer">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="lg" // Change to 'lg' for larger size
              role="status"
              aria-hidden="true"
              height="250px"
              width="250px"
            />
          </div>
        ) : checkUUID === true ?(
          <div className="msger">
            <div className="msger-chat">
              <div className="header">
                <div className="group">
                  <div className="msg-text-group">
                    <div className="msg-text1">VERA, The SolarBot</div>
                    {agentName && (
                      <div className="msg-text2">
                        <svg
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "5px" }}
                        >
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0AC947" />
                        </svg>
                        Online {`(${agentName})`}
                      </div>
                    )}
                  </div>

                  <div className="msg-text3">
                    {currentDateTime}
                  </div>
                </div>
              </div>
              <div className="chatmsg-container" id="scrollable-container">
                <div className="msg left-msg">
                  <div
                    className="msg-img"
                    style={{ backgroundImage: `url(${chatbotImage})` }}
                  ></div>

                  <div className="msg-bubble">
                    <div className="msg-text">
                      Hi, I’m Vera, your SolarBot that can answer all of your
                      questions surrounding solar for your home. I am a 3rd
                      party, reliable source without any ties to a specific
                      solar company. I am here to get you the most accurate,
                      fact checked information about solar and help you
                      determine if it makes sense for you! How can I help you
                      today?
                    </div>
                  </div>
                </div>
                <button
                  className="msg-bubble1 border-0 mt-2"
                  onClick={() => handleClick("how-solar-works")}
                >
                  <div className="msg-texta">How does solar work?</div>
                </button>
                <div
                  className="msg-bubble1a"
                  onClick={() => handleClick1("save-money")}
                >
                  <div className="msg-texta">
                    Will Solar save me money on my electric bill?
                  </div>
                </div>
                <div
                  className="msg-bubble1"
                  onClick={() => handleClick2("solar-is-free")}
                >
                  <div className="msg-texta">Is solar really free?</div>
                </div>

                <div className="msg-bubble1" onClick={toggleInputVisibility}>
                  <div className="msg-texta">Other Question ?</div>
                </div>

                <div>
                  <button className="msg-bubble1 border-0" onClick={handleShow}>
                    <div className="msg-texta">Talk to agent</div>
                  </button>

                  <FormModal
                    show={showModal}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    address={address}
                    setAddress={setAddress}
                    error={error}
                  />
                  {/* showInput */}
                  {showInput && notificationConfirmation &&(
                    <form className="msger-inputarea" onSubmit={handleClickSendMessage}>
                      <div className="msgchat-bar">
                        <div className="typing-bar agent-chat-box">
                          <input
                            type="text"
                            className="msger-input"
                            value={displayInputValue}
                            pattern="^[^\s].*"
                            onChange={handleInputChangeValue}
                            placeholder="Message..."
                            required
                          />
                          <button
                            type="button"
                            title="Close conversation"
                            className="close-chat"
                            onClick={closeSocket}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </button>
                        </div>
                        <button
                          type="submit"
                          className="msger-send-btn"
                        >
                          <div>
                            <svg
                              width="26"
                              height="23"
                              viewBox="0 0 26 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </form>
                  )}
                </div>

                <div className="chat-messages">
                  {messages.map((msg, index) => (
                    <div key={index}>
                      <div className="msg right-msg">
                        <div className="msg-bubble-right">
                          <div className="msg-text">{msg.user}</div>
                        </div>
                      </div>
                      <div className="msg-bubble custom-left">
                        <div className="msg-bubble-left">
                          <div className="msg-texta">{msg.bot}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                  {console.log("notification", notificationConfirmation)}
                {notificationConfirmation &&(<div className="chat-messages">
                  {chatMessage
                    .filter((data) => {
                      return data !== "";
                    })
                    .map((msg, index) => (
                      <>
                        <div key={index} className="msg-container-main-wrapper">
                          <div className="msg right-msg">
                            {msg.user1 && (
                              <div
                                className="msg-img"
                                style={{ backgroundImage: `url(${userIcon})` }}
                              ></div>
                            )}
                            {msg.user1 && (
                              <div className="msg-bubble-right">
                                <div className="msg-text">{msg.user1}</div>
                                <span style={{ fontSize: "10px" }}>
                                  {customerTime}
                                </span>
                              </div>
                            )}
                          </div>
                          {msg.user2 && (
                            <div className="msg">
                              {" "}
                              {/* Added a parent div */}
                              <div
                                className="msg-img"
                                style={{ backgroundImage: `url(${agentIcon})` }}
                              ></div>
                              <div className="msg-bubble">
                                <div className="msg-bubble-left">
                                  <div className="msg-texta">{msg.user2}</div>
                                  <span style={{ fontSize: "10px" }}>
                                    {customerTime}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          
                        </div>
                      </>
                    ))}
                  </div>
                )} 

                {isVisible && responses.length > 0 && (
                  <div className="msg-container">
                    {/* First response on the right side */}
                    <div className="msg right-msg">
                      <div className="msg-bubble-right">
                        {responses.length > 0 && (
                          <div className="msg-text">
                            <p>{responses[0]}</p>{" "}
                            {/* Display the first response */}
                          </div>
                        )}
                        {responses.some(
                          (resp) => resp.includes("yes") || resp.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleYesClick}
                              disabled={btnDisable.yes}
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              disabled={btnDisable.yes}
                              onClick={handleShow}
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                      {/* <div ref={bottomRef} /> */}
                    </div>

                    {/* Render the second response on the left side */}
                    {responses.length > 1 && (
                      <div className="msg-bubble custom-left">
                        <div className="msg-bubble-left">
                          <div className="msg-text">
                            <p>{responses[1]}</p>{" "}
                            {/* Display the second response */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* Render the third response on the right side */}
                {responses.length > 2 && (
                  <div className="msg-container">
                    <div className="msg right-msg">
                      <div className="msg-bubble-right">
                        <div className="msg-text">
                          <p>{responses[2]}</p>{" "}
                          {/* Display the third response */}
                        </div>
                        {responses.some(
                          (resp) => resp.includes("yes") || resp.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleYesHometownClick}
                              disabled={(responses?.length > 3 || responsesNew.length) > 0 ? "disable" : ""}
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              onClick={handleNoHometownClick}
                              disabled={(responses?.length > 3 || responsesNew.length) > 0 ? "disable" : ""}
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {responsesNew.length >= 1 &&(
                  <div className="msg-container">
                  <div className="msg right-msg">
                    <div className="msg-bubble-right">
                      <div className="msg-text">
                        <p>{responsesNew[0]}</p> {/* Display the forth response */}
                      </div>
                      {console.log("responsesNew",responsesNew.length)}
                      {responsesNew.some(
                          (resp) => resp.includes("yes") || resp.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleSpouseYesClick}
                              disabled={responsesNew?.length > 1 || responses.length > 3 ? "disable" : ""}
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              onClick={handleSpouseNoClick}
                              disabled={responsesNew?.length > 1 || responses.length > 3 ? "disable" : ""}
                            >
                              No
                            </button>
                          </div>
                        )}
                       
                    </div>
                  </div>
                  </div> 
                )}

                {/* Render the fifth response on the left side */}
                {responsesNew.length > 1 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responsesNew[1]}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the fourth response on the left side */}
                {responses.length > 3 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses[3]}</p> {/* Display the forth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the fifth response on the left side */}
                {responses.length > 4 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right">
                      <div className="msg-text">
                        <p>{responses[4]}</p> {/* Display the fifth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 5 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses[5]}</p> {/* Display the sixth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 6 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right">
                      <div className="msg-text">
                        <p>{responses[6]}</p>{" "}
                        {/* Display the seventh response */}
                      </div>
                      {responses.some(
                        (resp) => resp.includes("yes") || resp.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={handleYesBillClick}
                            disabled={responses?.length > 7 ? "disable" : ""}
                          >
                            Confirm
                          </button>
                          {/* <button
                            className="response-button"
                            onClick={handleYesBillClick}
                            disabled={responses?.length > 7 ? "disable" : ""}
                          >
                            No
                          </button> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 7 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses[7]}</p> {/* Display the sixth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the seventh response on the right side */}
                {responses.length > 8 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right">
                      <div className="msg-text">
                        <p>{responses[8]}</p>{" "}
                        {/* Display the seventh response */}
                      </div>
                      {responses.some(
                        (resp) => resp.includes("yes") || resp.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={handleLastYesClick}
                            disabled={responses?.length > 9 ? "disable" : ""}
                          >
                            Yes
                          </button>
                          <button
                            className="response-button"
                            onClick={handleNoClickForBill}
                            disabled={responses?.length > 9 ? "disable" : ""}
                          >
                            No
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Render the tenth response on the right side */}
                {responses.length > 9 && (
                  <>
                    <div>
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(1)}
                      >
                        <div className="msg-texta">
                          Q1:What if i need a new roof before going solar, or
                          while the panels are up there?
                        </div>
                      </button>
                    </div>
                    <div>
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(2)}
                      >
                        <div className="msg-texta">
                          Q2:What happens if i move while i have solar?
                        </div>
                      </button>
                    </div>
                    <div>
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(3)}
                      >
                        <div className="msg-texta">
                          Q3:What if something breaks, who is responsible to fix
                          my system?
                        </div>
                      </button>
                    </div>
                  </>
                )}

                {/* Render the tenth response on the right side */}
                {responses.length > 10 && (
                  <div className="msg right-msg">
                    {/* {console.log("responses------------------------akay",responses[13])} */}
                    <div className="msg-bubble-right">
                      <div className="msg-text">
                        <p>{responses?.[10]}</p>{" "}
                        {/* Display the nine response */}
                      </div>
                      {responses.some(
                        (resp) => resp.includes("yes") || resp.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={() =>
                              lastQuestionConfirmation("proposal_request")
                            }
                            disabled={responses?.length > 11 ? "disable" : ""}
                          >
                            Proposal Request
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {responses.length > 11 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses[11]}</p>
                      </div>
                    </div>
                  </div>
                )}
                {autoScroll()}
                {/* <div ref={endOfMessagesRef} className="auto-scroll"/> */}
              </div>
              {isInputVisible && (
                <form className="msger-inputarea">
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Something..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for address */}
              {responses.length > 1 && responses.length < 3 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Address..."
                        value={inputValue}
                        onChange={handleInputChange}
                        minLength={3}
                        maxLength={50}
                        pattern="^[^\s].*"
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for name */}
              {responses.length > 3 && responses.length < 5 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit2}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Full Name..."
                        title="Enter First Name & Last Name"
                        value={inputNameValue}
                        onChange={handleNameInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for email address */}
              {responses.length > 4 && responses.length < 6 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit3}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="email"
                        className="msger-input"
                        placeholder="Enter Email..."
                        value={inputEmailValue}
                        onChange={handleEmailInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for phone number */}
              {responses.length > 5 && responses.length < 7 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit4}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Phone Number..."
                        value={inputPhoneValue}
                        onChange={handlePhoneInputChange}
                        pattern="^[0-9]*$"
                        minLength={6}
                        maxLength={10}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for Electricity Bill */}
              {responses.length > 7 && responses.length < 9 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit5}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Electricity Bill..."
                        value={inputBillValue}
                        onChange={handleBillInputChange}
                        pattern="^[0-9]*$"
                        title="Required only numeric value"
                        minLength={1}
                        maxLength={8}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* <div className="loader"></div>
              <div className="error">
                <div className="error-icon">
                  <img src={ErrorIcon} alt="" />
                </div>
                <div className="error-msg">
                  <p>Error message</p>
                </div>
              </div> */}
            </div>
          </div>
        ): (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h1>Your application has been rejected.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
